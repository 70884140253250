document.addEventListener('DOMContentLoaded', () => {
  document.getElementById('year').innerText = "" + new Date().getFullYear()
})

function toggleMenu() {
  const navClassList = document.getElementById('nav').classList
  const mobileClosedFlag = 'nav-mobile-closed'

  if (navClassList.contains(mobileClosedFlag)) {
    navClassList.remove(mobileClosedFlag)
  } else {
    navClassList.add(mobileClosedFlag)
  }
}

document.getElementById('burger').addEventListener('click', () => {
  toggleMenu()
})


document.getElementById('app-button').addEventListener('click', function (e) {
  e.preventDefault()

  toggleMenu()
  document.querySelector(this.getAttribute('href')).scrollIntoView({
    behavior: 'smooth'
  })
})
